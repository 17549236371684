/* ./src/index.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;1,500&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  font-size: 12px;
}

body.pushable > .pusher {
  background: #fff;
}

#scrollbar::-webkit-scrollbar {
  width: 0px;
}

/* Track */
#scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 3px;
  width: 0px;
}

/* Handle */
#scrollbar::-webkit-scrollbar-thumb {
  background: #519ff2;
  border-radius: 3px;
}

/* Handle on hover */
#scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0877ee;
}

section::-webkit-scrollbar {
  width: 0px;
}

/* Track */
section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 3px;
  width: 0px;
}

/* Handle */
section::-webkit-scrollbar-thumb {
  background: #519ff2;
  border-radius: 3px;
}

/* Handle on hover */
section::-webkit-scrollbar-thumb:hover {
  background: #0877ee;
}


div::-webkit-scrollbar {
  width: 0px;
}

/* Track */
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 3px;
  width: 0px;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #519ff2;
  border-radius: 3px;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #0877ee;
}

@media only screen and (max-width: 768px) {
  .ui.table thead th {
    display: none;
  }
}



.playground {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.editor {
  width: 100%;
  min-height: 200px;
  background-color: #282c34;
  color: white;
  font-family: monospace;
  padding: 10px;
  border-radius: 5px;
}

.error {
  color: red;
  font-size: 14px;
}

.preview {
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}
